const firebaseConfig = {
    apiKey: "AIzaSyCYa490KJ_GJqsykw4668Z-CiZ2wfJ1tZ4",
    authDomain: "gwei-price-watcher.firebaseapp.com",
    projectId: "gwei-price-watcher",
    storageBucket: "gwei-price-watcher.appspot.com",
    messagingSenderId: "859407948329",
    appId: "1:859407948329:web:8fa4d1f6db64e32a7c5a38",
    measurementId: "G-RKYDJQ62M6"
  };

  export default firebaseConfig;